<template>
  <StateWrapper
    icon="mdi-progress-clock"
    :title="title"
    description="The feature is currently in development. We will notify you once it is
      ready."
  />
</template>

<script>
import StateWrapper from "./StateWrapper.vue";

export default {
  name: "WorkInProgress",

  components: { StateWrapper },

  props: {
    feature: {
      type: String,
      default: "",
    },
  },

  computed: {
    title() {
      return `${this.feature}, Coming soon`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
